import React, { Fragment, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AddContentIcon,
  BroadcastIcon,
  CancelIcon,
  DashboardIcon,
  PackageIcon,
  SettingsIcon,
  UsersIcon,
} from "../../assets/icons/Icons";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../components/ui/video-dialog";
import logo from "../../assets/images/logo.png";
import useToken from "../../hooks/useToken";
import toast from "react-hot-toast";
import interceptor, { baseUrl } from "../../../src/hooks/intercepter";
import { RotateCcw } from "lucide-react";

const Sidebar = ({ toggleSidebar, isSidebarOpen }) => {
  const { isAdmin } = useToken();
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const { token, user } = useToken();

  const [contentList, setContentList] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const currentVideoIndexRef = useRef(0);
  const [contentType, setContentType] = useState(null);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const imageTimerRef = useRef(null); // Timer reference for image
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (contentList.length === 0) { fetchContent(); }
  }, []);

  const fetchContent = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/combined-content/${user.id}`,
        token
      );
      if (response.status) {
        const newContentList = response.data.data.result;
        setContentList(newContentList);

        setContentType(newContentList[0]?.content_type);
        console.log("abc", contentList);
        currentVideoIndexRef.current = 0;
        if (isBroadcasting && currentVideoIndexRef.current >= newContentList.length) {
          currentVideoIndexRef.current = 0; // Reset to first content if index is out of bounds
        }
        // if (isBroadcasting) {
        //   loadContent(newContentList[0]);
        // }
        return newContentList
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Failed to fetch content. Please try again.");
    }
  };

  const handleBroadcastNow = () => {
    fetchContent().then((response) => {
      if (contentList.length > 0) {
        currentVideoIndexRef.current = 0;
        const currentContent = contentList[0];
        setContentType(currentContent?.content_type);
        setIsBroadcasting(true); // Start broadcasting
        // loadContent(currentContent);
        handleCountStart();
      } else {
        toast.error("No content available to broadcast.");
      }
    });
  };


  const handleVideoEnded = async () => {

    if (currentVideoIndexRef.current + 1 >= contentList.length) {

      await fetchContent().then((response) => {
        currentVideoIndexRef.current = 0;
        setCurrentVideoIndex(0);
        const nextContent = response[currentVideoIndexRef.current];
        setContentType(nextContent?.content_type);
        // loadContent(nextContent);
      });
    } else {

      currentVideoIndexRef.current = (currentVideoIndexRef.current + 1) % contentList.length;
      setCurrentVideoIndex(currentVideoIndexRef.current);
      const nextContent = contentList[currentVideoIndexRef.current];
      console.log(nextContent)
      setContentType(nextContent?.content_type);
      loadContent(nextContent);
    }
  };

  const loadContent = (currentContent) => {

    if (!currentContent) return;
    setContentType(currentContent.content_type);

    if (["png", "jpg", "jpeg", "qr_code"].includes(currentContent.content_type)) {
      setLoading(true);

      const tempImage = new Image();
      // tempImage.src = `${baseUrl}media/${currentContent.content_path}`;
      tempImage.src = `${baseUrl}media/${currentContent.content_path}?t=${new Date().getTime()}`; // Cache buster
      tempImage.onload = () => {
        debugger
        let eme = document.getElementById('image-container')
        // Remove existing image if it exists
        if (eme && eme.firstChild) {
          eme.removeChild(eme.firstChild);  // Remove the first child (previous image)
        }

        // if (imageRef.current) {
        setLoading(false);
        // imageRef.current.classList.remove("w3-animate-zoom");
        // void imageRef.current.offsetWidth;  // Trigger reflow
        // imageRef.current.classList.add("w3-animate-zoom");
        // imageRef.current.src = tempImage.src;

        const newImg = document.createElement("img");
        newImg.src = tempImage.src;  // Set the loaded image source
        newImg.alt = "Content";
        
        newImg.classList.add("object-contain", "w-full", "h-full", "w3-animate-zoom");

        document.getElementById('image-container').appendChild(newImg); // Assuming you have an element with ID 'image-container'


        // if (imageTimerRef.current) {
        //   clearTimeout(imageTimerRef.current);
        // }
        const duration =
          currentContent.source === "advertiser" &&
            currentContent.package_details?.content_duration
            ? currentContent.package_details.content_duration * 1000
            : 10000;
        handleUpdateFrequency(
          currentContent.id,
          currentContent.source,
          currentContent.content_type
        );
        debugger
        setTimeout(() => {
          handleVideoEnded();
        }, duration);
        // }
      };

      tempImage.onerror = () => {
        console.error("Failed to load image:", tempImage.src);
        handleVideoEnded();
        setLoading(false);
      };


    } else if (["mp4", "mov", "gif"].includes(currentContent.content_type)) {
      handleUpdateFrequency(
        currentContent.id,
        currentContent.source,
        currentContent.content_type
      );

      if (videoRef.current) {

        videoRef.current.src = `${baseUrl}media/${currentContent.content_path}`;
        videoRef.current.classList.remove("w3-animate-zoom");
        void videoRef.current.offsetWidth; // Trigger reflow
        videoRef.current.classList.add("w3-animate-zoom");

        videoRef.current.load();
        videoRef.current.play().catch((error) => {
          console.error("Failed to play video:", error);
        });
      }
    }
  };


  useEffect(() => {
    if (isBroadcasting && contentList.length > 0) {
      const currentContent = contentList[currentVideoIndexRef.current];
      loadContent(currentContent);
    }
    return () => {
      if (imageTimerRef.current) {
        clearTimeout(imageTimerRef.current); // Clear timeout on unmount or content change
      }
    };
  }, [isBroadcasting, contentList]);

  const handleUpdateFrequency = async (id, source, content_type) => {
    if (source === "advertiser") {
      try {
        const response = await interceptor.axiosPut(
          "PATCH",
          `users/update-content-frequency/${id}`,
          "",
          token
        );

        if (response.status === 200) {
          console.log("Content frequency updated successfully");
        }
      } catch (error) {
        console.error("Update request failed:", error);
      }
    }
  };

  const toggleFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };

  const handleCountStart = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/broadcast`,
        "",
        token
      );
      toggleFullScreen(document.getElementById("full-screenVideo"));
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  const handleCountEnd = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/cancel`,
        "",
        token
      );
      window.location.reload();
      setIsBroadcasting(false); // Stop broadcasting
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  return (
    <Fragment>
      <section
        className={`bg-background h-full w-[18.75rem] fixed top-0 left-0 z-50 transition-all duration-500 ${isSidebarOpen ? "hidden xl:block" : "block xl:hidden"
          }`}
      >
        <button
          onClick={toggleSidebar}
          className="xl:hidden ml-auto mt-4 mr-4 flex bg-blue w-6 h-6 rounded-full items-center justify-center"
        >
          <CancelIcon fill="white" width={16} height={16} />
        </button>

        <div className="block p-5">
          <img
            src={logo}
            width={200}
            height={23}
            className="block object-cover sm:w-[12.5rem] w-[8.5rem]"
            alt=""
          />
        </div>

        {/* Broadcaster Sidebar */}
        {!isAdmin ? (
          <ul className="flex flex-col gap-1.5 px-5 pt-10 overflow-auto h-full">
            <li>
              <NavLink
                to="/broadcaster/dashboard"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <DashboardIcon
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                      stroke=""
                      width={20}
                      height={20}
                    />
                    <span>Dashboard</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <Dialog>
                <DialogTrigger
                  className="flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 bg-transparent"
                  onClick={handleBroadcastNow}
                >
                  <BroadcastIcon
                    className="stroke-dark"
                    width={20}
                    height={20}
                  />
                  Diffuser maintenant
                </DialogTrigger>
                <DialogContent

                  className="bg-black border-none rounded-none min-w-full w-full h-screen flex items-center justify-center"
                  handleCountEnd={handleCountEnd}
                  id="full-screenVideo"
                >
                  <DialogDescription className="h-screen w-screen">
                    {isBroadcasting && contentList.length > 0 && (
                      <>
                        {["mov", "mp4", "gif"].includes(contentType) && (
                          <video
                            ref={videoRef}
                            className="object-contain  w-full h-full w3-animate-zoom"
                            autoPlay
                            muted
                            onEnded={handleVideoEnded}
                          >
                            <source
                              src={`${baseUrl}media/${contentList[currentVideoIndexRef.current]
                                ?.content_path
                                }`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {["png", "jpg", "jpeg", "qr_code"].includes(
                          contentType
                        ) && (

                            <>
                              {loading && (
                                <div className="absolute inset-0 flex items-center justify-center">
                                  {/* Loader element */}
                                  <div className="loader" style={{ color: 'white' }}>
                                    {/* Loading... */}
                                  </div>
                                </div>
                              )}
                              <div id="image-container"  className="w-full h-full">
                                {/* Dynamically created image will be appended here */}
                              </div>
                              {/* {imageRef && imageRef.current &&
                                <img
                                  ref={imageRef}
                                  alt="Content"
                                  src=""
                                  className="object-contain  w-full h-full"
                                /> 
                              }*/}
                            </>
                          )}
                      </>
                    )}
                  </DialogDescription>
                </DialogContent>
              </Dialog>
            </li>
            <li>
              <NavLink
                to="/broadcaster/add-content"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <AddContentIcon
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                      stroke=""
                      width={20}
                      height={20}
                    />
                    <span>Ajouter un contenu</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/broadcaster/reset-password"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <RotateCcw
                      width={19}
                      height={19}
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                    />
                    <span>Changer le mot de passe</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/broadcaster/settings"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <SettingsIcon
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                      stroke=""
                      width={20}
                      height={20}
                    />
                    <span>Paramètres</span>
                  </>
                )}
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="flex flex-col gap-1.5 px-5 pt-10 overflow-auto h-full">
            <li>
              <NavLink
                to="/admin/users"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <UsersIcon
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                      stroke=""
                      width={20}
                      height={20}
                    />
                    <span>Utilisatrices</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/packages"
                className={({ isActive }) =>
                  `flex items-center gap-2.5 rounded-xl text-sm font-medium text-dark px-4 py-2.5 transition duration-500 ${isActive ? "bg-blue text-white" : "bg-transparent"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <PackageIcon
                      className={`${isActive ? "stroke-white" : "stroke-dark"}`}
                      stroke=""
                      width={20}
                      height={20}
                    />
                    <span>Forfaits</span>
                  </>
                )}
              </NavLink>
            </li>
          </ul>
        )}
      </section>
    </Fragment>
  );
};

export default Sidebar;
